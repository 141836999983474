import { Box, Button, Card, CardContent, Typography } from "@mui/material"
import { AuthContext } from "helpers/auth"
import useFilingHelper from "hooks/useFilingHelper"
import useGeographicNameHelper from "hooks/useGeographicNameHelper"
import { isEmpty } from "lodash-es"
import { useCallback, useContext, useState } from "react"
import { useDropzone } from "react-dropzone"

const BasicDropzone = ({ actions, task, subtask, residence, filing, date }) => {
  const [uploadFiles, setUploadFiles] = useState([])
  const { getContinentCode } = useGeographicNameHelper();
  const onDrop = useCallback(acceptedFiles => {
    setUploadFiles([...uploadFiles, ...acceptedFiles])
  }, [uploadFiles])

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const auth = useContext(AuthContext)
  const { fetchFilingListData } = useFilingHelper();

  const handleSubmit = async (e) => {
    e.preventDefault()

    await Promise.all(uploadFiles.map(async (file) => {
      const getUploadURLResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/files/get-upload-url`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        }
      })

      const response = (await getUploadURLResponse.json())
      const s3UploadResponse = await fetch(response.uploadURL, {
        method: "PUT",
        headers: { "Content-Type": "multipart/form-data" },
        body: file
      })

      if (s3UploadResponse.status === 200) {
        const s3Key = response.s3Key
        const filename = file.name

        var body = { filename, s3Key }
        if (task) { 
          body.task = task._id 
          body.country = task.obligation?.country
          body.countryCode = task.obligation?.countryCode
          body.continentCode = task.obligation?.continentCode ?? getContinentCode({ countryCode: task.obligation?.countryCode })
          body.geographicalRegionCode = task.obligation?.geographicalRegionCode
          body.year = task.year
          body.quarter = task.quarter
          body.month = task.month
          body.entity = task.entity
        }
        if (subtask) { body.subtask = subtask._id }
        if (residence) { body.residence = residence._id }
        if (date) {
          body.country = date.country
          body.year = date.year
          body.quarter = date.quarter
        }
        if (filing) {
          body.filing = filing._id
          body.country = filing.country
          body.countryCode = filing.countryCode
          body.continentCode = filing.continentCode
          body.geographicalRegionCode = filing.geographicalRegionCode
          body.year = filing.year
          body.quarter = filing.quarter
          body.month = filing.month
          body.entity = filing.entity
        }

        await fetch(`${process.env.REACT_APP_API_PROXY}/api/files`, {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          },
          body: JSON.stringify(body)
        })
      }
    }))

    if (filing) {
      await fetchFilingListData({ auth });
    }

    setUploadFiles([])
    actions.toggleUpdate()
    if (task && !subtask) { actions.handleClose() }
  }

  const files = uploadFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Card elevation={12}>
      <CardContent>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Typography sx={{ fontSize: 14, border: "1px dashed", p: 1, mb: 1 }} color="text.secondary" gutterBottom {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            Drag files here, or click to select files
          </Typography>

          <Typography sx={{ fontSize: 14, mb: 3 }} color="text.primary" gutterBottom {...getRootProps({className: 'dropzone'})}>
            {files}
          </Typography>

          <Button variant="contained" type="submit" disabled={isEmpty(uploadFiles)}>Upload</Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default BasicDropzone
