import {
  AccountBalance as AccountBalanceIcon,
  AccountTree as AccountTreeIcon,
  AssuredWorkload as AssuredWorkloadIcon,
  AttachFile as AttachFileIcon,
  Business as BusinessIcon,
  CurrencyExchange as CurrencyExchangeIcon,
  Paid as PaidIcon,
  Person as PersonIcon,
  PointOfSale as PointOfSaleIcon,
  Settings as SettingsIcon,
  Work as WorkIcon
} from "@mui/icons-material"
import { Box, Button, Divider, Drawer, ListItem, Link as MUILink, Typography } from "@mui/material"
import { Link, useMatch } from "react-router-dom"

const Logo = () => {
  return (
    // <img src="/smile_logo.png" width="100%" alt="" />
    <img src="/ceg_logo.png" width="100%" alt="" />
    // <img src="/skanstec_logo.png" width="100%" alt="" />
    //<img src="/inkarnate_logo.svg" width="100%" alt="" />
  )
}

const NavItem = ({ href, icon, title }) => {
  const active = useMatch(href)

  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        mb: 0.5,
        py: 0,
        px: 2
      }}
    >
      <Button
        component={Link} to={href}
        startIcon={icon}
        disableRipple
        sx={{
          backgroundColor: active && "rgba(255,255,255, 0.08)",
          borderRadius: 1,
          color: active ? "secondary.main" : "neutral.300",
          fontWeight: active && "fontWeightBold",
          justifyContent: "flex-start",
          px: 3,
          textAlign: "left",
          textTransform: "none",
          width: "100%",
          "& .MuiButton-startIcon": {
            color: active ? "secondary.main" : "neutral.400"
          },
          "&:hover": {
            backgroundColor: "rgba(255,255,255, 0.08)"
          }
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          {title}
        </Box>
      </Button>
    </ListItem>
  )
}

const Sidebar = (props) => {
  const { open, onClose, sidebarWidth } = props

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: sidebarWidth,
        },
      }}
      variant="persistent"
    >
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* Sidebar upper part */}
        <Box sx={{ flexGrow: 1, my: 3 }}>
          <Box sx={{ px: 3, pt: 1 }}><Logo /></Box>

          <Divider sx={{ borderColor: "#2D3748", my: 3 }} />

          <NavItem href={"/tasks"} icon={<AssuredWorkloadIcon fontSize="small" />} title={"Compliance Tracker"} />
          <NavItem href={"/obligations"} icon={<WorkIcon fontSize="small" />} title={"Obligations"} />
          <NavItem href={"/projects"} icon={<AccountTreeIcon fontSize="small" />} title={"Projects"} />
          <NavItem href={"/returns"} icon={<PointOfSaleIcon fontSize="small" />} title={"VAT Analysis"} />
          <NavItem href={"/trades"} icon={<CurrencyExchangeIcon fontSize="small" />} title={"Trades"} />
          <NavItem href={"/payments"} icon={<PaidIcon fontSize="small" />} title={"Payments"} />
          <NavItem href={"/own-account"} icon={<AccountBalanceIcon fontSize="small" />} title={"Own Bank Accounts"} />
          <NavItem href={"/third-party-account"} icon={<AccountBalanceIcon fontSize="small" />} title={"Third Party Bank Accounts"} />
          <NavItem href={"/customers"} icon={<PersonIcon fontSize="small" />} title={"Directory"} />
          <NavItem href={"/entities"} icon={<BusinessIcon fontSize="small" />} title={"Entities"} />
          {/* <NavItem href={"/filings"} icon={<AssuredWorkloadIcon fontSize="small" />} title={"Filings"} /> */}
          {/* <NavItem href={"/tasks"} icon={<FormatListBulletedIcon fontSize="small" />} title={"Tasks"} /> */}
          {/* <NavItem href={"/residences"} icon={<LocationOnIcon fontSize="small" />} title={"Residences"} /> */}
          {/* <NavItem href={"/properties"} icon={<HouseIcon fontSize="small" />} title={"Real Estate"} /> */}

          <Divider sx={{ borderColor: "#2D3748", my: 3 }} />

          <NavItem href={"/files"} icon={<AttachFileIcon fontSize="small" />} title={"Files"} />
          <NavItem href={"/settings"} icon={<SettingsIcon fontSize="small" />} title={"Settings"} />
        </Box>

        <Divider sx={{ borderColor: '#2D3748' }} />

        {/* Sidebar lower part */}
        <Box sx={{ px: 2, py: 3 }}>
          <Typography color="neutral.500" variant="body2">
            <MUILink href="https://www.csequitygroup.com" color="inherit" target="_blank">
              © Cornerstone Group (CEG).
            </MUILink>
          </Typography>
          <Typography color="neutral.500" variant="body2">
            All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Drawer>
  )
}

export default Sidebar
