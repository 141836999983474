import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { AuthContext } from "helpers/auth";
import useMonexApiHelper from "hooks/useMonexApiHelper";
import useTaskHelper from "hooks/useTaskHelper";
import { selectTaskList, selectTradeList } from "redux/slices/selectors";

import { StyledTableCell, StyledTableRow } from "components/common/StyledTable";
import TableLoader from "components/common/TableLoader";
import TradeStatusPill from "components/tasks/TradeStatusCell";

const TradePage = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const taskList = useSelector(selectTaskList);
    const tradeList = useSelector(selectTradeList);

    const tradeListTableData = useMemo(() => {
        return tradeList?.flatMap((trade) => {
            const task = taskList?.find((task) => task.tradeId === trade.id);
            if (task) {
                const { name: taskName } = task;
                return [{ ...trade, taskName }];
            }

            //Feb 27, 2024
            return []; // Remove trade items without task linked
            // else{
            //     const { name: taskName } = "NO TASK ASSOCIATED";
            //     return [{ ...trade, taskName }];
            // }
        }) ?? [];
    }, [taskList, tradeList]);

    const isPageLoading = useMemo(() => taskList?.length === 0 || tradeListTableData.length === 0, [taskList?.length, tradeListTableData.length]);

    const { fetchTaskListData } = useTaskHelper();
    const { fetchTradeList } = useMonexApiHelper();

    useEffect(() => {
        if (auth.user && taskList?.length === 0) {
            fetchTaskListData({ auth });
        }
    }, [auth, fetchTaskListData, taskList?.length]);

    useEffect(() => {
        if (tradeList?.length === 0) {
            fetchTradeList();
        }
    }, [dispatch, fetchTradeList, tradeList?.length]);

    const handleTradeDetailRedirect = (tradeId) => {
        navigate(`/trades/${tradeId}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Trades</Typography>
            </Grid>

            <Grid item xs={12}>
                {isPageLoading ? (
                    <TableLoader />
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Task Name</StyledTableCell>
                                    <StyledTableCell align="center">Deadline Date</StyledTableCell>
                                    <StyledTableCell align="center">Rate</StyledTableCell>
                                    <StyledTableCell align="center">Buy Amount</StyledTableCell>
                                    <StyledTableCell align="center">Sell Amount</StyledTableCell>
                                    <StyledTableCell align="center">Trade Status</StyledTableCell>
                                    <StyledTableCell align="center">Trade Code</StyledTableCell>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {tradeListTableData?.map((row) => {
                                    const { id: tradeId, code, valueDate, status, rate, sellCurrencyCode, sellAmount, buyCurrencyCode, buyAmount, taskName } = row;
                                    return (
                                        <StyledTableRow
                                            key={tradeId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell>{taskName}</StyledTableCell>
                                            <StyledTableCell align="center">{valueDate}</StyledTableCell>
                                            <StyledTableCell align="center">{rate}</StyledTableCell>
                                            <StyledTableCell align="center">{buyCurrencyCode} {buyAmount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="center">{sellCurrencyCode} {sellAmount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="center"><TradeStatusPill status={status} /></StyledTableCell>
                                            <StyledTableCell align="center">{code}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Button
                                                    onClick={() => handleTradeDetailRedirect(tradeId)}
                                                    variant="outlined"
                                                >
                                                    VIEW
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
};

export default TradePage;