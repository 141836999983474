import { Box, Grid, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import DeleteDialog from "components/common/DeleteDialog"
import CustomerModal from "components/customers/CustomerModal"
import { AuthContext } from "helpers/auth"
import { COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries"
import { Fragment, useContext, useEffect, useState } from "react"
import ReactCountryFlag from "react-country-flag"

import "../common/StyledTable/styles.css"
import { CustomerSecionLabelMapping } from "./constants"

const CustomerPage = () => {
  const [customerList, setCustomerList] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const toggleUpdate = () => setUpdated(!updated)

  const customerColumns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Fragment>
          <CustomerModal customer={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"contact"} deleteFunction={() => deleteCustomer(params.row)} />
        </Fragment>
      ),
      headerClassName: "styled-header",
    },
    {
      field: "country",
      headerName: "Country",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Fragment>
            <ReactCountryFlag countryCode={COUNTRY_NAME_TO_COUNTRY_CODES[params.row.country]} />&nbsp;{params.row.country}
          </Fragment>
        )
      },
      headerClassName: "styled-header",
    },
    { field: "firstname", headerName: "First Name", minWidth: 125, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "lastname", headerName: "Last Name", minWidth: 125, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "title", headerName: "Title", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "companyName", headerName: "Company Name", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "companyAddress", headerName: "Company Address", minWidth: 300, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    {
      field: "section",
      headerName: "Discipline",
      minWidth: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "styled-header",
      renderCell: (params) => CustomerSecionLabelMapping[params.row.section] ?? '-',
    },
    { field: "phone", headerName: "Phone", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "landline", headerName: "Landline", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "email", headerName: "Email", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
    { field: "website", headerName: "Website", minWidth: 200, align: "center", headerAlign: "center", headerClassName: "styled-header", },
  ]

  const deleteCustomer = async (customer) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/customers/${encodeURIComponent(customer._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setCustomerList((customerList) => customerList.filter((c) => c._id !== customer._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchCustomerData = async () => {
        const customerResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/customers`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const customers = await customerResponse.json()
        setCustomerList(customers)
      }

      fetchCustomerData()
    }
  }, [updated, auth])

  return (
    <Grid container spacing={3}>
      {/* Header and add button */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Directory</Typography>
          <Box sx={{ m: 1 }}>
            <CustomerModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>

      {/* Customers */}
      <Grid item xs={12} sx={{ height: 550 }}>
        <DataGrid
          rows={customerList}
          columns={customerColumns}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          getRowClassName={() => "styled-row"}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CustomerPage
