import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";

import UserSelect from "components/common/UserSelect";
import {
  CustomerSecionLabelMapping,
  CustomerSectionOptions,
} from "components/customers/constants";
import { AuthContext } from "helpers/auth";
import {
  MONTH_MAPPING_PER_QUARTER,
  PERIODS,
  PHASES,
  QUARTERS,
  STATUSES,
  TYPES,
  YEARS,
} from "helpers/constants";
import {
  COUNTRY_CODE_TO_NAME,
  COUNTRY_PAYMENT_ALLOWED_LIST,
} from "helpers/countries";
import {
  CANADA_PROVINCE_CODE_TO_NAME,
  CANADA_PROVINCE_LIST,
  CONTINENT_GROUP_COUNTRIES_MAPPING,
  CONTINENT_GROUP_ORDER,
  US_STATES_LIST,
  US_STATE_CODE_TO_NAME,
} from "helpers/regions";
import { UsersContext } from "helpers/users";
import { ErrorMessage } from "helpers/validations";
import useTaskModal from "hooks/useTaskModal";
import { isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectCompanyList, selectCustomerList } from "redux/slices/selectors";
import { PaymentStatusList, TaskStatusLabelMapping } from "../constants";
import { classes } from "./styles";

export const TaskModalMode = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  DUPLICATE: "DUPLICATE",
};
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const TaskModal = ({
  obligation,
  task,
  actions,
  onClose,
  mode = TaskModalMode.CREATE,
}) => {
  const auth = useContext(AuthContext);
  const users = useContext(UsersContext);
  const companyList = useSelector(selectCompanyList);
  const customerList = useSelector(selectCustomerList);

  const paymentStatusList = PaymentStatusList;
  const { defaultValues, currencyCode } = useTaskModal({ obligation, task });

  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({ defaultValues, mode: "onSubmit" });
  const quarter = watch("quarter");
  const continentCode = watch("continentCode");
  const countryCode = watch("countryCode");
  const period = watch("period");
  const shouldShowStateField = useMemo(
    () => countryCode === "US" || countryCode === "CA",
    [countryCode]
  );
  const stateOrProvinceLabel = useMemo(
    () => (countryCode === "US" ? "States" : "Provinces"),
    [countryCode]
  );

  // const [showPaymentStatus, setShowPaymentStatus] = useState(true);
  const isEditMode = mode === TaskModalMode.EDIT;

  // useEffect(() => {
  //   updatePaymentInfo();
  // }, [countryCode]);

  useEffect(() => { }, []);

  // const updatePaymentInfo = () => {
  //   if (
  //     COUNTRY_PAYMENT_ALLOWED_LIST.includes(COUNTRY_CODE_TO_NAME[countryCode])
  //   )
  //     setShowPaymentStatus(true);
  //   else setShowPaymentStatus(false);
  // };
  const handleClose = useCallback(() => {
    onClose?.();
    if (task) {
      // Editing task in task page
      actions.toggleUpdate();
    }
  }, [actions, onClose, task]);

  const handleCreateTask = useCallback(
    async (data) => {
      try {
        const url = isEditMode
          ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(
            task?._id
          )}`
          : `${process.env.REACT_APP_API_PROXY}/api/tasks`;
        const method = isEditMode ? "PUT" : "POST";

        const enrichedData = {
          ...data,
          country: COUNTRY_CODE_TO_NAME[data.countryCode],
          contactPerson: data.contactPerson?._id,
          managingPartner: data.managingPartner?._id,
          paymentStatus: data.paymentStatus,
          month: data.period === "Quarterly" ? "" : data.month,
          quarter: data.period === "Monthly" ? "" : data.quarter,
        };

        console.log("enrichedData");
        console.log(enrichedData);

        await fetch(url, {
          method: method,
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth?.user.token,
          },
          body: JSON.stringify(enrichedData),
        });
        toast.success(
          `Task successfully ${isEditMode ? "updated" : "created"}!`,
          { autoClose: 6500 }
        );
        handleClose();
      } catch (error) {
        console.log(error);
        toast.error(error.message, { autoClose: 5000 });
      }
    },
    [auth?.user.token, handleClose, isEditMode, task]
  );

  const renderTaskInformation = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>TASK INFORMATION</Typography>
        </Grid>

        {/* Task name */}
        <Grid item xs={8}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Task Name"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.name)}
              {...register("name", { required: ErrorMessage.Required })}
            />
            {errors.name && (
              <FormHelperText error>{errors.name.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Task phase */}
        <Grid item xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink>Phase</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="Phase"
              notched
              InputLabelProps={{ shrink: true }}
              {...register("phase")}
              defaultValue={defaultValues.phase}
            >
              {PHASES.map((phase) => (
                <MenuItem key={phase} value={phase}>
                  {phase}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Description */}
        <Grid item xs={8}>
          <TextField
            fullWidth
            variant="outlined"
            label="Description"
            InputLabelProps={{ shrink: true }}
            {...register("description")}
          />
        </Grid>

        {/* Deadline date */}
        <Grid item xs={3}>
          <Controller
            control={control}
            name="deadlineDate"
            render={({ field }) => {
              const { onChange: onDateChange } = field ?? {};
              return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Deadline Date"
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                    inputFormat="yyyy-MM-dd"
                    {...field}
                    onChange={(value) =>
                      onDateChange(moment(value).format("YYYY-MM-DD"))
                    }
                  />
                </LocalizationProvider>
              );
            }}
          />
        </Grid>

        {/* Type */}
        <Grid item xs={6}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink>Type</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="Type"
              notched
              {...register("taskType")}
              defaultValue={defaultValues.taskType}
            >
              {TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Legal Entity */}
        <Grid item xs={5}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink>Legal Entity</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              label="Legal Entity"
              notched
              error={Boolean(errors.entity)}
              {...register("entity", { required: ErrorMessage.Required })}
              defaultValue={defaultValues.entity}
            >
              {users?.entityList?.entities?.map((ent) => (
                <MenuItem
                  key={ent._id}
                  value={ent._id}
                  onClick={() => setValue("entity", ent._id)}
                >
                  {ent.name}
                </MenuItem>
              ))}
            </Select>
            {errors.entity && (
              <FormHelperText error>{errors.entity.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Assigned users */}
        <Grid item xs={6}>
          <Controller
            control={control}
            name="assignedUsers"
            render={({ field: { value, onChange } }) => (
              <UserSelect
                assignedUsers={value}
                setAssignedUsers={onChange}
                label="Ultimate Responsible Party"
              />
            )}
          />
        </Grid>

        {/* Status */}
        <Grid item xs={5}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink>Status</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              error={Boolean(errors.status)}
              label="Status"
              notched
              {...register("status", { required: ErrorMessage.Required })}
              defaultValue={defaultValues.status}
            >
              {STATUSES.map((status) => (
                <MenuItem key={status} value={status}>
                  {TaskStatusLabelMapping[status]}
                </MenuItem>
              ))}
            </Select>
            {errors.status && (
              <FormHelperText error>{errors.status.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTaskLocation = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>TASK LOCATION</Typography>
        </Grid>

        {/* Region */}
        <Grid item xs={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>Region</InputLabel>
            <Select
              displayEmpty
              notched
              label="Region"
              error={Boolean(errors.continentCode)}
              {...register("continentCode", {
                onChange: (e) => {
                  setValue("continentCode", e.target.value);
                  setValue("countryCode", "");
                  setValue("geographicalRegionCode", "");
                },
                required: ErrorMessage.Required,
              })}
              defaultValue={defaultValues.continentCode}
              disabled={!isEditMode}
            >
              {CONTINENT_GROUP_ORDER.map((continentCode) => (
                <MenuItem key={continentCode} value={continentCode}>
                  {continentCode}
                </MenuItem>
              ))}
            </Select>
            {errors.continentCode && (
              <FormHelperText error>
                {errors.continentCode.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Country */}
        <Grid item xs={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>Country</InputLabel>
            <Select
              displayEmpty
              notched
              label="Country"
              error={Boolean(errors.countryCode)}
              {...register("countryCode", {
                onChange: (e) => {
                  setValue("countryCode", e.target.value);
                  setValue("geographicalRegionCode", "");
                },
                required: ErrorMessage.Required,
              })}
              defaultValue={defaultValues.countryCode}
              disabled={!isEditMode}
            >
              {CONTINENT_GROUP_COUNTRIES_MAPPING[continentCode]?.map(
                (countryCode) => (
                  <MenuItem key={countryCode} value={countryCode}>
                    {COUNTRY_CODE_TO_NAME[countryCode]}
                  </MenuItem>
                )
              )}
            </Select>
            {errors.countryCode && (
              <FormHelperText error>
                {errors.countryCode.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* State/Province */}
        {shouldShowStateField && (
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink>{stateOrProvinceLabel}</InputLabel>
              <Select
                displayEmpty
                notched
                label={stateOrProvinceLabel}
                {...register("geographicalRegionCode", {
                  required: ErrorMessage.Required,
                })}
                defaultValue={defaultValues.geographicalRegionCode}
                disabled={!isEditMode}
              >
                {countryCode === "US" &&
                  US_STATES_LIST.map((stateCode) => {
                    const stateName = US_STATE_CODE_TO_NAME[stateCode];
                    return (
                      <MenuItem key={stateCode} value={stateCode}>
                        {stateName}
                      </MenuItem>
                    );
                  })}
                {countryCode === "CA" &&
                  CANADA_PROVINCE_LIST.map((provinceCode) => {
                    const provinceName =
                      CANADA_PROVINCE_CODE_TO_NAME[provinceCode];
                    return (
                      <MenuItem key={provinceCode} value={provinceCode}>
                        {provinceName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderTaskPeriod = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>TASK PERIOD</Typography>
        </Grid>

        {/* Period */}
        <Grid item xs={3}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel shrink>Period</InputLabel>
            <Select
              fullWidth
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              label="Period"
              notched
              {...register("period", {
                onChange: (e) => {
                  const newValue = e.target.value;
                  console.log("newValue", newValue);
                  if (newValue === "Quarterly") {
                    console.log("Changing month");
                    setValue("month", "");
                  } else if (newValue === "Monthly") {
                    console.log("Changing quarter");
                    setValue("quarter", "");
                  }
                  setValue("period", newValue);
                },
              })}
              defaultValue={defaultValues.period}
            >
              {PERIODS.map((period) => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Year */}
        <Grid item xs={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>Year</InputLabel>
            <Select
              displayEmpty
              notched
              label="Year"
              error={Boolean(errors.year)}
              {...register("year", {
                required: ErrorMessage.Required,
              })}
              defaultValue={defaultValues.year}
            >
              {YEARS.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
            {errors.year && (
              <FormHelperText error>{errors.year.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Quarter */}
        {period !== "Monthly" && (
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink>Quarter</InputLabel>
              <Select
                displayEmpty
                notched
                label="Quarter"
                error={Boolean(errors.quarter)}
                disabled={period !== "Quarterly"}
                {...register("quarter", {
                  onChange: (e) => {
                    setValue("quarter", e.target.value);
                    setValue("month", "");
                  },
                })}
                defaultValue={defaultValues.quarter}
              >
                {QUARTERS.map((quarter) => (
                  <MenuItem key={quarter} value={quarter}>
                    {quarter}
                  </MenuItem>
                ))}
              </Select>
              {errors.quarter && (
                <FormHelperText error>{errors.quarter.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}

        {/* Month */}
        {period !== "Quarterly" && (
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink>Month</InputLabel>
              <Select
                displayEmpty
                notched
                label="Month"
                error={Boolean(errors.month)}
                disabled={period !== "Monthly"}
                {...register("month", {
                  onChange: (e) => {
                    setValue("month", e.target.value);
                  },
                })}
                defaultValue={defaultValues.month}
              >
                {(
                  MONTH_MAPPING_PER_QUARTER[quarter] ??
                  MONTH_MAPPING_PER_QUARTER.ALL
                ).map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              {errors.month && (
                <FormHelperText error>{errors.month.message}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const renderServiceProvider = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>Service Provider</Typography>
        </Grid>

        <Grid item xs={5}>
          <Controller
            control={control}
            name="serviceProvider"
            defaultValue={defaultValues.serviceProvider}
            rules={{ required: ErrorMessage.Required }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                }}
                options={companyList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Service Provider Name"
                    error={Boolean(errors.serviceProvider)}
                  />
                )}
              />
            )}
          />
        </Grid>

        {/* Managing Partner Name */}
        <Grid item xs={5}>
          <Controller
            control={control}
            name="managingPartner"
            defaultValue={defaultValues.managingPartner}
            rules={{ required: ErrorMessage.Required }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                }}
                options={customerList}
                getOptionLabel={(customer) => {
                  const { firstname, lastname } = customer ?? {};
                  return firstname && lastname
                    ? `${customer?.firstname} ${customer?.lastname}`
                    : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Managing Partner Name"
                    error={Boolean(errors.managingPartner)}
                  />
                )}
              />
            )}
          />
        </Grid>

        {/* Contact Person Name */}
        <Grid item xs={5}>
          <Controller
            control={control}
            name="contactPerson"
            defaultValue={defaultValues.contactPerson}
            rules={{ required: ErrorMessage.Required }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                }}
                options={customerList}
                getOptionLabel={(customer) => {
                  const { firstname, lastname } = customer ?? {};
                  return firstname && lastname
                    ? `${customer?.firstname} ${customer?.lastname}`
                    : "";
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contact Person Name"
                    error={Boolean(errors.contactPerson)}
                  />
                )}
              />
            )}
          />
        </Grid>

        {/* Section */}
        <Grid item xs={5}>
          <Controller
            control={control}
            name="section"
            defaultValue={defaultValues.section}
            rules={{ required: ErrorMessage.Required }}
            render={({ field: { value, onChange } }) => (
              <Autocomplete
                value={value}
                onChange={(event, item) => {
                  onChange(item);
                }}
                options={CustomerSectionOptions.map(
                  (section) => CustomerSecionLabelMapping[section]
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Section"
                    error={Boolean(errors.section)}
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderPaymentInformation = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>PAYMENT INFORMATION</Typography>
        </Grid>

        {/* Amount */}
        <Grid item xs={5}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Amount"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencyCode}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.amount)}
              {...register("amount", {
                valueAsNumber: true,
                min: {
                  value: 1,
                  message: ErrorMessage.PositiveAmount,
                },
              })}
            />
            {errors.amount && (
              <FormHelperText error>{errors.amount.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Client Reference */}
        <Grid item xs={6}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Client Reference"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.clientReference)}
              {...register("clientReference", {
                pattern: {
                  message: ErrorMessage.SpecialCharactersNotAllowed,
                  value:
                    /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/,
                },
              })}
            />
            {errors.clientReference && (
              <FormHelperText error>
                {errors.clientReference.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Payment Purpose */}
        <Grid item xs={5}>
          <FormControl sx={{ width: "100%" }}>
            <TextField
              fullWidth
              variant="outlined"
              label="Payment Purpose"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.paymentPurpose)}
              {...register("paymentPurpose")}
            />
            {errors.paymentPurpose && (
              <FormHelperText error>
                {errors.paymentPurpose.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        {/* Payment status */}
        <Grid item xs={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>{"Payment Status"}</InputLabel>
            <Select
              id="paymentStatus"
              displayEmpty
              notched
              label={"Payment Status"}
              {...register("paymentStatus", {
                onChange: (e) => {
                  setValue("paymentStatus", e.target.value);
                },
              })}
              defaultValue={defaultValues.paymentStatus}
            >
              {paymentStatusList.map((payment) => {
                return (
                  <MenuItem key={payment.status} value={payment.status}>
                    {capitalize(payment.status)}
                  </MenuItem>
                );
              })}
            </Select>
            {errors.paymentStatus && (
              <FormHelperText error>
                {errors.paymentStatus.message}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

      </Grid>
    </Grid>
  );

  const modalHeader = useMemo(() => {
    switch (mode) {
      case TaskModalMode.DUPLICATE:
        return "Duplicate";
      case TaskModalMode.EDIT:
        return "Edit";
      case TaskModalMode.CREATE:
      default:
        return "Create";
    }
  }, [mode]);

  return (
    <Modal open={!isEmpty(obligation)} onClose={handleClose}>
      <Box sx={classes.modal}>
        <Grid container spacing={3}>
          {/* Header */}
          <Grid item xs={12}>
            <Typography sx={classes.modalHeader}>
              {modalHeader} Task for Obligation:
            </Typography>
            <Typography sx={classes.modalSubtitle}>
              {obligation.name}
            </Typography>
            <Divider sx={{ p: 1 }} />
          </Grid>

          {renderTaskInformation()}
          {renderTaskLocation()}
          {renderTaskPeriod()}
          {renderServiceProvider()}
          {renderPaymentInformation()}

          {/* Submit */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleSubmit(handleCreateTask)}
            >
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TaskModal;
