import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

import { ErrorMessage } from 'helpers/validations';
import useMonexApiHelper from 'hooks/useMonexApiHelper';
import usePaymentModal from 'hooks/usePaymentModal';
import useStepperHelper from 'hooks/useStepperHelper';
import useToastDisplay from 'hooks/useToastDisplay';
import { selectSubmitLoadingStatePaymentModal } from 'redux/slices/selectors';
import { CREATE_PAYMENT_MODAL_STEPS } from './constants';

import './styles.css';

const TaskPaymentModal = ({ task, onClose }) => {
    const {
        defaultValues,
        currencyCode,
        thirdPartyBankAcct,
        thirdPartyBankAcctList,
        thirdPartyCurrencyCode,
        balance,
        trade,
        today,
        maxPaymentDate,
    } = usePaymentModal({ task });
    console.log("third party accounts list", thirdPartyBankAcctList)
    console.log("third party accounts", thirdPartyBankAcct)

    const {
        activeStep,
        handleNext,
        handleBack,
    } = useStepperHelper();

    const { postPayment, fetchPaymentList } = useMonexApiHelper();
    const { showSuccessSnackbar, showErrorSnackbar } = useToastDisplay();

    const [selectedThirdPartyBankAcc, setSelectedThirdPartyBankAcc] = useState(thirdPartyBankAcct)
    const { register, handleSubmit, setValue, watch, control, formState: { isValid, isSubmitting, errors } } = useForm({ defaultValues, mode: 'onChange' });

    const valueDate = watch('valueDate');
    const reference = watch('reference');
    const isPaymentModalSubmitting = useSelector(selectSubmitLoadingStatePaymentModal);

    const isFormValid = isValid && valueDate !== 'Invalid date';
    const proceedTooltipText = useMemo(() => {
        if (!isFormValid) {
            return 'Fill out all required fields.';
        }
        return '';
    }, [isFormValid]);

    const handleCreatePayment = useCallback(async (data) => {
        const postPaymentResponse = await postPayment({ task, formData: data });
        if (postPaymentResponse?.message) {
            showErrorSnackbar(postPaymentResponse.message);
        } else {
            await fetchPaymentList();
            showSuccessSnackbar(`Successfully created payment for ${task.name}.`);
            onClose();
        }
    }, [fetchPaymentList, onClose, postPayment, showErrorSnackbar, showSuccessSnackbar, task]);

    return (
        <Modal
            open
            onClose={isPaymentModalSubmitting ? undefined : onClose} // disable close if submitting
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="box">
                {/* Header */}
                <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                    <Typography variant="h6">
                        {CREATE_PAYMENT_MODAL_STEPS[activeStep].title} <strong className="task-name">{task.name}</strong>
                    </Typography>
                    <Divider sx={{ p: 1 }} />
                </Grid>

                {/* Content */}
                {activeStep === 0 && (
                    <Grid container spacing={3}>
                        {/* Task name */}
                        <Grid item xs={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <TextField
                                    id="purpose"
                                    label="Purpose of Payment"
                                    placeholder="Enter payment purpose"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={Boolean(errors.purpose)}
                                    {...register("purpose", { required: ErrorMessage.Required })}
                                />
                                {errors.purpose && <FormHelperText error>{errors.purpose.message}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <Tooltip title="Amount is fixed from trade buy value." arrow placement="top">
                                <TextField
                                    id="amount"
                                    label="Amount to be paid"
                                    placeholder={`Amount in ${thirdPartyCurrencyCode}`}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">{thirdPartyCurrencyCode}</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    value={trade?.buyAmount}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Tooltip>
                        </Grid>

                        <Grid item xs={6}>
                            <Tooltip title="Amount is fixed from trade sell value." arrow placement="top">
                                <TextField
                                    id="amount"
                                    label="Amount in USD equivalent"
                                    placeholder={`Amount in ${currencyCode}`}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment>,
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    value={trade?.sellAmount}
                                    InputLabelProps={{ shrink: true }}
                                    {...register("amount", { required: true })}
                                />
                            </Tooltip>
                        </Grid>

                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="valueDate"
                                rules={{ required: ErrorMessage.Required }}
                                render={({ field }) => {
                                    const { onChange: onDateChange } = field ?? {};
                                    return (
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Deliver payment on"
                                                placeholder="Enter delivery date"
                                                renderInput={(params) => <TextField {...params} fullWidth />}
                                                inputFormat="yyyy-MM-dd"
                                                minDate={today}
                                                maxDate={maxPaymentDate}
                                                {...field}
                                                onChange={(value) => onDateChange(moment(value).format("YYYY-MM-DD"))}
                                            />
                                        </LocalizationProvider>
                                    );
                                }}
                            />
                            {errors.valueDate && <FormHelperText error>{errors.valueDate.message}</FormHelperText>}
                        </Grid>

                        <Grid item xs={6}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel shrink>
                                    {"Third Party Bank Account"}
                                </InputLabel>
                                <Select
                                    id="bankAccountId"
                                    displayEmpty
                                    notched
                                    label={"Third Party Bank Account"}
                                    {...register("bankAccountId", {
                                        onChange: (e) => {
                                            setValue('bankAccountId', e.target.value);
                                            setSelectedThirdPartyBankAcc(thirdPartyBankAcctList.find((item) => item.id === e.target.value))
                                        },
                                        required: true
                                    })}
                                    defaultValue={selectedThirdPartyBankAcc?.id}
                                >
                                    {thirdPartyBankAcctList.map((bankAcc) => {
                                        return (
                                            <MenuItem key={bankAcc.id} value={bankAcc.id}>
                                                {bankAcc.nickname}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                {errors.bankAccountId && <FormHelperText error>{errors.bankAccountId.message}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl sx={{ width: "100%" }}>
                                <TextField
                                    id="reference"
                                    label="Payment Reference"
                                    placeholder="Enter payment reference"
                                    variant="outlined"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={Boolean(errors.reference)}
                                    {...register("reference", {
                                        required: ErrorMessage.Required,
                                        pattern: {
                                            message: ErrorMessage.SpecialCharactersNotAllowed,
                                            value: /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/
                                        },
                                    })}
                                />
                                {errors.reference && <FormHelperText error>{errors.reference.message}</FormHelperText>}
                            </FormControl>
                        </Grid>

                        {/* Submit */}
                        <Grid item xs={12} className="footer">
                            <Box className="balance">
                                Available delivery balance:
                                <strong>{balance.currencyCode} {balance.balance}</strong>
                            </Box>
                            <Tooltip title={proceedTooltipText} arrow placement="top">
                                <Box className="button">
                                    <LoadingButton
                                        variant="contained"
                                        type="submit"
                                        fullWidth
                                        disabled={!isFormValid}
                                        onClick={handleNext}
                                    >
                                        <Typography variant="button">
                                            PROCEED
                                        </Typography>
                                    </LoadingButton>
                                </Box>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
                {activeStep === 1 && (
                    <Box>
                        <Grid item xs={12}>
                            <strong>PAYMENT SUMMARY</strong>
                            <Box className="summary-container">
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Amount to be Paid</Grid>
                                    <Grid item xs={8}>{trade?.buyCurrencyCode} {trade?.buyAmount}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Equivalent USD</Grid>
                                    <Grid item xs={8}>{trade?.sellCurrencyCode} {trade?.sellAmount}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Credit bank account</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.accountName}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Value date</Grid>
                                    <Grid item xs={8}>{valueDate}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Reference</Grid>
                                    <Grid item xs={8}>{reference}</Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <strong>BANK ACCOUNT DETAILS</strong>
                            <Box className="summary-container">
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Account</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.nickname ?? selectedThirdPartyBankAcc?.accountName ?? '-'}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Account number</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.accountNumber}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">Bank name</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.bankName ?? '-'}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">IBAN</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.iban ?? '-'}</Grid>
                                </Grid>
                                <Grid item xs={12} className="summary-row">
                                    <Grid item xs={4} className="summary-label">SWIFT</Grid>
                                    <Grid item xs={8}>{selectedThirdPartyBankAcc?.bic ?? '-'}</Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} className="footer">
                            <Box className="button">
                                <LoadingButton loading={isSubmitting} variant="contained" onClick={handleSubmit(handleCreatePayment)} fullWidth>
                                    <Typography variant="button">
                                        INSTRUCT PAYMENT
                                    </Typography>
                                </LoadingButton>
                            </Box>
                            <Box className="button">
                                <Button disabled={isSubmitting} variant="outlined" onClick={handleBack} fullWidth>
                                    <Typography variant="button">
                                        BACK
                                    </Typography>
                                </Button>
                            </Box>
                            <Box className="button">
                                <Button disabled={isSubmitting} variant="outlined" onClick={onClose} fullWidth>
                                    <Typography variant="button">
                                        CANCEL
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

export default TaskPaymentModal;
