import { useCallback, useMemo, useState } from "react";

import { Box, Grid, Paper, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { LegendToggle } from "@mui/icons-material";
import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import CustomTabPanel, { a11yProps } from "components/common/CustomTabPanel";
import { COUNTRY_CODE_TO_NAME } from "helpers/countries";
import { CANADA_PROVINCE_CODE_TO_NAME, US_STATE_CODE_TO_NAME } from "helpers/regions";
import { selectMapChartTab, selectPieChartTab, selectTaskPageFilters } from "redux/slices/selectors";
import { INITIAL_TASK_CHART_FILTERS, setMapChartTab, setPieChartTab, setTaskPageFilters } from "redux/slices/tasksSlice";
import TaskMapChartTooltip from "../TaskMapChartTooltip";
import CanadaMapChart from "../charts/CanadaMapChart";
import FilingStatusPieChart from "../charts/FilingStatusPieChart";
import PaymentStatusPieChart from "../charts/PaymentStatusPieChart";
import UnitedStatesMapChart from "../charts/UnitedStatesMapChart";
import WorldMapChart from "../charts/WorldMapChart";
import { TaskPieChartFilter } from "../constants";
import TaskMapFilters from "./TaskMapFilters";
import TaskMapLegend from "./TaskMapLegend";
import { classes } from "./styles";

const TaskMapSection = ({ data = [] }) => {
    const dispatch = useDispatch();
    const [isLegendOpen, setIsLegendOpen] = useState(false);
    const activeTab = useSelector(selectMapChartTab);
    const pieChartTab = useSelector(selectPieChartTab);
    const pageFilters = useSelector(selectTaskPageFilters);
    const {
        country: countryFilter,
        region: regionFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);

    const pieChartLabel = useMemo(() => {
        const prefix = pieChartTab === TaskPieChartFilter.Filing ? 'Filing' : 'Payment';
        let suffix = 'ALL COUNTRIES';
        if (countryFilter === 'US' && US_STATE_CODE_TO_NAME[regionFilter]) {
            suffix = `${US_STATE_CODE_TO_NAME[regionFilter]}, United States`;
        } else if (countryFilter === 'CA' && CANADA_PROVINCE_CODE_TO_NAME[regionFilter]) {
            suffix = `${CANADA_PROVINCE_CODE_TO_NAME[regionFilter]}, Canada`;
        } else if (countryFilter) {
            suffix = COUNTRY_CODE_TO_NAME[countryFilter];
        }
        const label = `${prefix} STATUS FOR ${suffix}`;
        return label.toUpperCase();
    }, [countryFilter, pieChartTab, regionFilter]);

    const handleFilterChange = (props) => {
        console.log("handleFilterChange", props)
        dispatch(setTaskPageFilters({ pageFilters: props }));
    };

    const handleTabChange = (event, newValue) => {
        switch (newValue) {
            case 0: {
                handleFilterChange(INITIAL_TASK_CHART_FILTERS);
                break;
            }
            case 1: {
                handleFilterChange({
                    ...INITIAL_TASK_CHART_FILTERS,
                    country: 'US',
                });
                break;
            }
            case 2: {
                handleFilterChange({
                    ...INITIAL_TASK_CHART_FILTERS,
                    country: 'CA',
                });
                break;
            }
            default:
                break;
        }
        dispatch(setMapChartTab({ mapChartTab: newValue }));
    };

    const handlePieChartTabChange = (even, newValue) => {
        if (newValue !== null) {
            dispatch(setPieChartTab({ pieChartTab: newValue }))
        }
    };

    const renderPieChart = useCallback(() => {
        if (pieChartTab === TaskPieChartFilter.Payment) {
            return (
                <PaymentStatusPieChart data={data} />
            );
        }
        return (
            <FilingStatusPieChart data={data} />
        );
    }, [data, pieChartTab]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper sx={classes.paper}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                            <Tab label="World" {...a11yProps(0)} />
                            <Tab label="United States" {...a11yProps(1)} />
                            <Tab label="Canada" {...a11yProps(2)} />
                        </Tabs>
                    </Box>

                    {/* Charts */}
                    <Grid container sx={classes.charts}>
                        {/* Map Charts */}
                        <Grid
                            item
                            lg={8}
                            md={12}
                            xs={12}
                            sx={classes.mapChartContainer}
                        >
                            <CustomTabPanel value={activeTab} index={0}>
                                <TaskMapChartTooltip />
                                <WorldMapChart data={data} />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={1}>
                                <TaskMapChartTooltip />
                                <UnitedStatesMapChart data={data} />
                            </CustomTabPanel>
                            <CustomTabPanel value={activeTab} index={2}>
                                <TaskMapChartTooltip />
                                <CanadaMapChart data={data} />
                            </CustomTabPanel>
                        </Grid>

                        {/* Pie Charts */}
                        <Grid item xs={4}>
                            <Grid container sx={classes.pieChartContainer}>
                                <Typography
                                    textAlign="center"
                                    sx={classes.pieChartHeaderText}
                                >
                                    {pieChartLabel}
                                </Typography>
                                <Box sx={classes.pieChart}>
                                    {renderPieChart()}
                                </Box>
                                <Box sx={classes.pieChartFilterSection}>
                                    <ButtonWithTooltip
                                        size="small"
                                        variant="outlined"
                                        tooltipText="View status legend"
                                        onClick={() => setIsLegendOpen(true)}
                                    >
                                        <LegendToggle />
                                    </ButtonWithTooltip>
                                    <ToggleButtonGroup
                                        size="small"
                                        exclusive
                                        value={pieChartTab}
                                        onChange={handlePieChartTabChange}
                                    >
                                        <ToggleButton
                                            value={TaskPieChartFilter.Filing}
                                        >
                                            Filing
                                        </ToggleButton>
                                        <ToggleButton
                                            value={TaskPieChartFilter.Payment}
                                        >
                                            Payment
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Filters */}
                    <TaskMapFilters data={data} handleFilterChange={handleFilterChange} />
                </Paper>
            </Grid>
            {isLegendOpen && <TaskMapLegend onClose={() => setIsLegendOpen(false)} />}
        </Grid>
    );
}

export default TaskMapSection;
