export const TaskStatus = {
  Pending: "Pending",
  Done: "Done",
  Overdue: "Tasks Overdue",
};

export const TaskStatusColorMapping = {
  [TaskStatus.Overdue]: "#ff0000",
  [TaskStatus.Done]: "#00a19c",
  [TaskStatus.Pending]: "#fec901",
};

export const TaskStatusLabelMapping = {
  [TaskStatus.Overdue]: "Overdue",
  [TaskStatus.Done]: "Done",
  [TaskStatus.Pending]: "In Progress",
};

export const PaymentStatus = {
  Pending: "pending",
  Completed: "completed",
  Canceled: "canceled",
};

export const PaymentStatusList = [
  { id: 1, status: "pending" },
  { id: 2, status: "completed" },
  { id: 3, status: "canceled" },
];

export const PaymentStatusColorMapping = {
  [PaymentStatus.Canceled]: "#ff0000",
  [PaymentStatus.Completed]: "#00a19c",
  [PaymentStatus.Pending]: "#fec901",
};

export const PaymentStatusLabelMapping = {
  [PaymentStatus.Pending]: "Pending",
  [PaymentStatus.Completed]: "Completed",
  [PaymentStatus.Canceled]: "Canceled",
};

export const TradeStatus = {
  AwaitingSettlement: "awaitingSettlement",
  ReadyForDelivery: "readyForDelivery",
  Delivered: "delivered",
  Reversed: "reversed",
};

export const TradeStatusColorMapping = {
  [TradeStatus.Reversed]: "#ff0000",
  [TradeStatus.Delivered]: "#00a19c",
  [TradeStatus.ReadyForDelivery]: "#fec901",
  [TradeStatus.AwaitingSettlement]: "#fec901",
};

export const TradeStatusLabelMapping = {
  [TradeStatus.AwaitingSettlement]: "Awaiting Settlement",
  [TradeStatus.ReadyForDelivery]: "Ready for Delivery",
  [TradeStatus.Delivered]: "Delivered",
  [TradeStatus.Reversed]: "Reversed",
};

export const TaskPieChartFilter = {
  Filing: "Filing",
  Payment: "Payment",
};
