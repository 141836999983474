export const CustomerSection = {
    Accounting: 'ACCOUNTING',
    TaxAdvisory: 'TAX_ADVISORY',
    InternationalExpansion: 'INTERNATIONAL_EXPANSION',
    Audit: 'AUDIT',
    Lawyer: 'LAWYER',
    Banker: 'BANKER',
    Partner: 'PARTNER'

};

export const CustomerSecionLabelMapping = {
    [CustomerSection.Accounting]: 'Accounting Manager',
    [CustomerSection.TaxAdvisory]: 'Tax Advisory',
    [CustomerSection.InternationalExpansion]: 'International Expansion',
    [CustomerSection.Audit]:  'Audit',
    [CustomerSection.Lawyer]: 'Lawyer',
    [CustomerSection.Banker]: 'Banker',
    [CustomerSection.Partner]: 'Partner',
};

export const CustomerSectionOptions = [
    CustomerSection.Accounting,
    CustomerSection.TaxAdvisory,
    CustomerSection.InternationalExpansion,
    CustomerSection.Audit,
    CustomerSection.Lawyer,
    CustomerSection.Banker,
    CustomerSection.Partner

];
