import { Edit as EditIcon } from "@mui/icons-material"
import { Autocomplete, IconButton, Box, Button, Divider, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import UserSelect from "components/common/UserSelect"
import { TaskStatusLabelMapping } from "components/tasks/constants"
import { AuthContext } from "helpers/auth"
import { SnackbarContext } from "helpers/snackbar"
import { UsersContext } from "helpers/users"
import moment from "moment"
import { Fragment, useContext, useMemo, useState } from "react"
import { SelectValidator, ValidatorForm } from "react-material-ui-form-validator"
import { classes } from "../tasks/TaskModal/styles"
import { CANADA_PROVINCE_CODE_TO_NAME, CANADA_PROVINCE_LIST, CONTINENT_GROUP_COUNTRIES_MAPPING, CONTINENT_GROUP_ORDER, US_STATE_CODE_TO_NAME, US_STATES_LIST } from "helpers/regions"
import { COUNTRY_CODE_TO_NAME, COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries"

const STATUSES = ["Pending", "Done", "Tasks Overdue"]

const ProjectModal = ({ task, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (task) {
      // Editing task in task page
      actions.toggleUpdate()
    } else {
      setName("")
      setDescription("")
      setDeadlineDate(moment(new Date()).format("YYYY-MM-DD"))
      setEntity("")
      setStatus("")
      setAssignedUsers([])
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [name, setName] = useState(task ? task.name : "")
  const [description, setDescription] = useState(task ? task.description : "")
  const [deadlineDate, setDeadlineDate] = useState(task ? task.deadlineDate : moment(new Date()).format("YYYY-MM-DD"))
  const [entity, setEntity] = useState(task ? task.entity?._id : "")
  const [status, setStatus] = useState(task ? task.status : "")

  //Task Location
  const [continentCode, setContinentCode] = useState(task ? task.continentCode ?? "" : "")
  const [countryCode, setCountryCode] = useState(task ? task.countryCode ?? "" : "")
  const [geographicalRegionCode, setGeographicalRegionCode] = useState(task ? task.geographicalRegionCode ?? "" : "")

  const shouldShowStateField = useMemo(() => countryCode === 'US' || countryCode === 'CA', [countryCode]);
  const stateOrProvinceLabel = useMemo(() => countryCode === 'US' ? 'States' : 'Provinces', [countryCode]);

  const [assignedUsers, setAssignedUsers] = useState(task ? task.assignedUsers.map((u) => (u._id)) : [])
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const snackbar = useContext(SnackbarContext)

  const handleSubmit = async () => {
    try {
      const url = task ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}` : `${process.env.REACT_APP_API_PROXY}/api/tasks`
      const method = task ? "PUT" : "POST"
      let country = COUNTRY_CODE_TO_NAME[countryCode]
      await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({
          name,
          description,
          deadlineDate,
          entity,
          status,
          assignedUsers,
          continentCode,
          countryCode,
          geographicalRegionCode,
          country
        })
      })
      actions.toggleUpdate()
      snackbar.setMessage({ message: `Project successfully ${task ? "updated" : "created"}!`, severity: "success" })
      handleClose()
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }


  const renderTaskLocation = () => (
    <Grid item xs={12}>
      <Grid container sx={classes.container}>
        <Grid item xs={12}>
          <Typography sx={classes.headerText}>
            TASK LOCATION
          </Typography>
        </Grid>

        {/* Region */}
        <Grid item xs={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>
              Region
            </InputLabel>
            <Select
              displayEmpty
              notched
              label="Region"
              onChange={(e) => {
                setContinentCode(e.target.value);
                setCountryCode('');
                setGeographicalRegionCode('');
              }}
              defaultValue={continentCode}
            >
              {CONTINENT_GROUP_ORDER.map(continentCode => (
                <MenuItem
                  key={continentCode}
                  value={continentCode}
                >
                  {continentCode}
                </MenuItem>
              ))}
            </Select>
            {/* {errors.continentCode && <FormHelperText error>{errors.continentCode.message}</FormHelperText>} */}
          </FormControl>
        </Grid>

        {/* Country */}
        <Grid item xs={3}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>
              Country
            </InputLabel>
            <Select
              displayEmpty
              notched
              label="Country"
              onChange={(e) => {
                setCountryCode(e.target.value);
                setGeographicalRegionCode('');
              }}
              value={countryCode}
            >
              {CONTINENT_GROUP_COUNTRIES_MAPPING[continentCode]?.map(countryCode => (
                <MenuItem
                  key={countryCode}
                  value={countryCode}
                >
                  {COUNTRY_CODE_TO_NAME[countryCode]}
                </MenuItem>
              ))}
            </Select>
            {/* {errors.countryCode && <FormHelperText error>{errors.countryCode.message}</FormHelperText>} */}
          </FormControl>
        </Grid>

        {/* State/Province */}
        {shouldShowStateField && (
          <Grid item xs={3}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink>
                {stateOrProvinceLabel}
              </InputLabel>
              <Select
                displayEmpty
                notched
                label={stateOrProvinceLabel}
                onChange={(e) => {
                  setGeographicalRegionCode(e.target.value);
                }}
                value={geographicalRegionCode}
              >
                {countryCode === 'US' ?
                  US_STATES_LIST.map((stateCode) =>
                    <MenuItem key={stateCode} value={stateCode}>
                      {US_STATE_CODE_TO_NAME[stateCode]}
                    </MenuItem>) :
                  CANADA_PROVINCE_LIST.map((provinceCode) =>
                    <MenuItem key={provinceCode} value={provinceCode}>
                      {CANADA_PROVINCE_CODE_TO_NAME[provinceCode]}
                    </MenuItem>
                  )}
              </Select>

            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Fragment>
      {task ?
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton>
        :
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Project
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {task?.name || "New project"}
                <Divider sx={{ p: 1 }} />
              </Grid>

              {/* Task name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Task Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Description */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>

              {/* Deadline date */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Deadline Date"
                    value={deadlineDate}
                    onChange={(deadlineDate) => { setDeadlineDate(deadlineDate) }}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Legal Entity */}
              <Grid item xs={3}>
                <SelectValidator
                  fullWidth
                  select
                  variant="outlined"
                  label="Legal Entity"
                  value={entity}
                  onChange={(e) => setEntity(e.target.value)}
                  validators={["required"]}
                  errorMessages={["This field is required"]}
                >
                  {users.entityList?.entities?.map(ent => (
                    <MenuItem key={ent._id} value={ent._id}>{ent.name}</MenuItem>
                  ))}
                </SelectValidator>
              </Grid>

              {/* Status */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {STATUSES.map(status => (
                    <MenuItem key={status} value={status}>{TaskStatusLabelMapping[status]}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Assigned users */}
              <Grid item xs={6}>
                <UserSelect assignedUsers={assignedUsers} setAssignedUsers={setAssignedUsers} />
              </Grid>
              {task &&
                <Grid item xs={12}>
                  {renderTaskLocation()}
                </Grid>}

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ProjectModal
